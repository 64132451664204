import StoreForm from '~/util/storeFormExtend';
const storeForm = new StoreForm();

export const state = () => ({
    venues: [],
    tagDict: [],
    amenityCategories: [],
    fields: {
        filter: '',
    },
});

export const mutations = {
    ...storeForm.mutations,
    setVenues(state, payload) {
        state.venues = payload;
    },
    settagDict(state, payload) {
        state.tagDict = payload;
    },
    setAmenityCategories(state, payload) {
        state.amenityCategories = payload;
    },
};

export const actions = {
    async loadVenues() {
        const response = await this.$axios.request({
            method: 'get',
            url: '/proxy/vm-view/v1/content/venues',
        });

        if (response?.data) {
            const processed = response?.data.map(item => ({ ...item, tags: item?.tags ? item.tags.split(', ') : [] }));

            await this.commit('map/setVenues', processed);

            // Create dict of events by tags for to create amenity filters for map
            const tagDict = processed.reduce((obj, it) => {
                if ('tags' in it && it.tags?.length) {
                    const tags = it.tags.map(item => item.toLowerCase());

                    for (const tag of tags) {
                        if (!(tag in obj) || !obj[tag]) {
                            obj[tag] = [];
                        }
                        obj[tag] = [...obj[tag], Object.freeze(it)];
                    }
                }

                return obj;
            }, {});

            await this.commit('map/settagDict', tagDict);
        }
    },
    setFilters(ctx) {
        // console.log(ctx);
    },
};

export const getters = {
    ...storeForm.getters,
    getVenues: state => state.venues,
    getTags: state => state.venues.reduce((acc, curr) => {
        // Get all the tags from all venues to one list and filter unique items
        if (Array.isArray(curr.tags)) {
            return [...acc, ...curr.tags];
        }

        return acc;
    }, []).filter((v, i, a) => a.findIndex(t => (t === v)) === i)
          .sort(),
    getTagDict: state => state.tagDict,
    getAmenityCategories: state => state.amenityCategories,
};
