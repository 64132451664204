
    import breakpoints from '~/mixins/breakpoints';

    export default {
        name: 'ContentSlider',
        mixins: [breakpoints],
        props: {
            title: {
                type: String,
                required: true,
                default: '',
            },
            slides: {
                type: Array,
                required: true,
                default: () => [],
            },
            seeMoreLink: {
                title: {
                    type: String,
                    required: true,
                },
                url: {
                    type: String,
                    required: true,
                },
                appearance: {
                    type: String,
                    required: false,
                },
                type: [Object, Boolean],
                required: false,
                default: false,
            },
            cardSize: {
                type: String,
                required: true,
                validator(value) {
                    return ['small', 'large'].includes(value);
                },
                default: 'large',
            },
        },
        data() {
            const bps = {};
            bps[this.$breakpoints.desktop] = { perView: 2 };
            bps[this.$breakpoints.largeMobile] = { perView: 1 };

            return {
                active: 0,
                bps,
            };
        },
        computed: {
            showLastButton() {
                if (this.atMostMobile) {
                    return this.active >= this.filteredSlides.length - 1;
                } else if (this.atMostTablet) {
                    return this.active >= this.filteredSlides.length - 2;
                }

                return this.active >= this.filteredSlides.length - 3;
            },
            iconProperties() {
                if (this.isDesktop) {
                    return {
                        size: 'xxxl',
                        icon: 'slider-arrow',
                    };
                }

                return {
                    size: 'xxl',
                    icon: 'slider-arrow',
                };
            },
            filteredSlides() {
                if (this.cardSize === 'small') {
                    return this.slides.filter(item => item.contentType === 'contentCard');
                }

                return this.slides.filter(item => item.contentType !== 'contentCard');
            },
        },
    };
