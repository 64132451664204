import { DateTime, Interval } from 'luxon';

export default ({ app, route }, inject) => {
    inject('util', {
        uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
                /[xy]/g,
                c => {
                    const radix = 16;
                    const r = Math.random() * radix | 0, v = c === 'x' ? r : (r & 0x3 | 0x8); // eslint-disable-line no-magic-numbers

                    return v.toString(radix);
                },
            );
        },
        localeLink(link) {
            if (!link.url || link.url.length === 0) {
                return app.$paths.getPath('landingPage');
            }
            switch (link.urlType) {
                case 'externalUrl':
                case 'assetUrl':
                    return link.url;

                default:
                case 'educationPage':
                case 'standardPage':
                    switch (app.i18n.locale) {
                        case 'en':
                            return `/en/${link.url}`;

                        default:
                        case 'fi':
                            return `/${link.url}`;
                    }
                    break;
            }
        },
        initDate(date) {
            if (!date) return '';

            if (date.isLuxonDateTime) {
                return date;
            }
                switch (typeof(date)) {
                    case 'string':
                        return DateTime.fromISO(date, { zone: 'Europe/Helsinki' });
                    break;
                    case 'object':
                        return DateTime.fromISO(date.toISOString(), { zone: 'Europe/Helsinki' });
                    break;
                }

        },
        getDateString(from, to) {
            if (from && to) {
                const fromDate = this.initDate(from);
                const toDate = this.initDate(to);

                if (this.isSameDay(fromDate, toDate)) {
                    return fromDate.toFormat('dd.MM.yyyy');
                } else if (fromDate.get('year') === toDate.get('year')) {
                    return `${fromDate.toFormat('dd.MM')} - ${toDate.toFormat('dd.MM.yyyy')}`;
                }

                return `${fromDate.toFormat('dd.MM.yyyy')} - ${toDate.toFormat('dd.MM.yyyy')}`;
            }

            return '-';
        },
        b64encode(string) {
            return Buffer.from(string).toString('base64');
        },
        b64decode(string) {
            return Buffer.from(string, 'base64').toString('utf8');
        },
        dateToString(inputDate) {
            const date = inputDate ?? new Date();
            if (typeof (date) === 'object') {
                return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            }

            return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        },
        // format for backend queries
        dateToUTCString(date) {
            const ts = this.initDate(date);

            return ts.toUTC().toISO();
        },
        domIsDescendant(parent, child) {
             let node = child.parentNode;
             while (node !== null) {
                 if (node === parent) {
                     return true;
                 }
                 node = node.parentNode;
             }

             return false;
        },
        personsToCount(persons) {
            if (typeof(persons) === 'object') {
                let count = 0;
                Object.keys(persons).forEach(key => {
                    if (key !== 'pets') {
                        count = count + persons[key];
                    }
                });

                return count;
            }
            throw new Error('Persons is not an object');
        },
        restoreCart() {
            if (app.$storage.getUniversal('cart') && Object.keys(app.$storage.getUniversal('cart')).length > 0) {
                Object.keys(app.$storage.getUniversal('cart')).forEach(key => {
                    app.store.commit('cart/setValueFromStorage', {
                        key,
                        value: app.$storage.getUniversal('cart')[key],
                    });
                });
                app.store.dispatch('cart/getTotals');
            };
        },
        parseImage(imageResource, conversion = 0) {
            if (imageResource === null || !imageResource) {
                return null;
            }
            // Sort conversions by largest first
            let conversions = [];
            if (imageResource?.conversions?.length) {
                conversions = [...imageResource.conversions];
                conversions.sort((a, b) => (a.name.toUpperCase() === 'FULL HD'? -1 : b.name.toUpperCase() === 'FULL HD' ? 1 : b.name - a.name));
            }

            if (imageResource.url && conversions.length > conversion) {
                return {
                    url: conversions[conversion]?.url,
                };
            } else if (imageResource.placeholder) {
                return {
                    placeholder: imageResource.placeholder,
                };
            };
        },
        isSameDay(dateToCheck, actualDate) {
            const start = this.initDate(dateToCheck);
            const end = this.initDate(actualDate);

            return start.startOf('day').equals(end.startOf('day'));
        },
        generateGtmProduct(product, type = '', pagePath = null, mainTitle= '') {
            /* eslint-disable camelcase */

            // Get category data for product
            const classification = product?.classification ?? product?.product?.classification;
            const productUrl = pagePath
                ?? product?.product?.pagePath
                ?? product?.product_pagePath
                ?? product?.pagePath;
            let categories = [];
            let categoryObj = undefined;
            if (productUrl?.length) {
                categories = this.generateCategoriesFromNav(productUrl);
            }

            if (!categories?.length) {
                let specialUrl = null;
                switch (productUrl) {
                    case 'fennada':
                        specialUrl = 'hotelli-fennada';
                        break;
                    case 'pihkala':
                        specialUrl = 'sporttihotelli-pihkala';
                        break;
                }
                if (specialUrl !== null) {
                    categories = this.generateCategoriesFromNav(specialUrl);
                }
            }

            if (categories?.length) {
                categoryObj = {};
                for (let i = 0; i < categories.length; i++) {
                    if (i === 0) {
                        categoryObj.item_category = categories[i];
                    } else if (i < 3) {
                        categoryObj[`item_category${i+1}`] = categories[i];
                    }
                }
            } else if (classification) {
                categoryObj = {
                    item_category: classification,
                };
            }

            // Return product data by different product data types
            if (type === 'addToCart') {
                return {
                    item_name: mainTitle ?? product?.product?.attributes?.marketing_name,
                    item_id: product.product?.assortmentCode ?? product.product?.code ?? product.code,
                    price: product?.price ?? product?.priceWithTax,
                    currency: 'EUR',
                    quantity: '1',
                    ...categoryObj,
                };
            } else if (type === 'cart') {
                return {
                    item_name: product.title,
                    item_id: product.productCode,
                    price: product.price,
                    currency: 'EUR',
                    quantity: '1',
                    ...categoryObj,
                };
            }

            return {
                item_name: product?.marketing_name ?? product?.title,
                item_id: product?.code ?? product?.productCode,
                price: product?.start_price ?? product?.price,
                currency: 'EUR',
                quantity: '1',
                ...categoryObj,
            };

            /* eslint-enable camelcase */
        },
        // Generate categories for GTM by going through navTwo
        generateCategoriesFromNav(productUrl) {
            const { navTwo } = app.store.state.navigation;
            let result = false;
            for (const item of navTwo) {
                const res = funnySearch(item, productUrl);
                if (Array.isArray(res)) {
                    result = res;
                }
            }

            if (Array.isArray(result)) {
                return result.reverse();
            }

            return false;

            // Check if wanted string is the url param in any of the nav,
            // and pass all parent categories if match is found
            function funnySearch(obj, search, acc = []) {
                // Return if object is null
                if (!obj) {
                    return false;
                }

                // Check if current object is the one being searched for
                if (obj?.url?.length) {
                    if (search === obj.url) {
                        return true;
                    }
                }

                if (obj?.children?.length) {
                    for (const child of obj.children) {
                        const res = funnySearch(child, search, acc);
                        // If child has passed title array, add current title as well
                        if (Array.isArray(res)) {
                            return [...res, obj.title];
                        }
                        // If child is a match, pass current obj title upwards
                        if (res && 'title' in obj && obj.title.length) {
                            return [obj.title];
                        }
                    }
                }

                return false;
            }
        },
        roundTo(num, length = 2) {
            return (Math.round( (Number(num) + Number.EPSILON) * 100) / 100).toFixed(length);
        },
        formatPrice(price, length = 2) {
            return `${this.roundTo(price, length).replace('.', ',')} €`;
        },
        formatArticleDate(dateString) {
            const date = new Date(dateString);

            return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
        },
        formatTime(date) {
            const ts = this.initDate(date);

            if (ts.isLuxonDateTime) {
                return ts.toFormat('HH:mm');
            }

            return '-';
        },
        duration(from, to) {
            if (from.isLuxonDateTime && to.isLuxonDateTime) {
                const Duration = Interval.fromDateTimes(from, to);

                if (Duration.length('hours') && Duration.length('minutes') % 60 === 0) {
                    return app.i18n.t('units.durationHours', [
                        Duration.length('hours'),
                    ]);
                } else if (Duration.length('hours') >= 1 && Duration.length('minutes') % 60 > 0) {
                    return app.i18n.t('units.durationHoursMinutes', [
                        Math.floor(Duration.length('hours')),
                        Duration.length('minutes') % 60,
                    ]);
                } else if (Duration.length('minutes') > 0) {
                    return app.i18n.t('units.durationMinutes', [Duration.length('minutes')]);
                }
            }

            return '-';
        },
        parseUrl(url) {
            if (url) {
                let current = route.fullPath.match(/^\/preview/) === null ? '' : '/preview';
                current = `${current}${app.i18n.locale !== 'fi' ? `/${app.i18n.locale}` : ''}`;
                current = `${current}/${url}`;

                return current;
            }

            return null;
        },
        expandPersonsToGuests(persons) {
            const guests = [];

            for (let i = 0; i < persons.numberOfAdults; i++) {
                guests.push({
                    type: 'adult',
                    firstName: 'Adult 1',
                    lastName: 'Adult 2',
                });
            };

            for (let i = 0; i < persons.numberOfChildrenYounger; i++) {
                guests.push({
                    type: 'child_younger',
                    firstName: 'ChildYounger 1',
                    lastName: 'ChildYounger 2',
                });
            };

            for (let i = 0; i < persons.numberOfChildrenOlder; i++) {
                guests.push({
                    type: 'child_older',
                    firstName: 'ChildOlder 1',
                    lastName: 'ChildOlder 2',
                });
            };

            return guests;
        },
        formatPriceHidingZeroCents(price) {
            const p = Number(price);
            const hasCents = p.toFixed(2).slice(-2) !== '00';

            return p.toLocaleString('fi-FI', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: hasCents ? 2 : 0,
                maximumFractionDigits: hasCents ? 2 : 0,
            });
        },
    });
};
