
export default {
    props: {
        isBoxed: {
            type: Boolean,
            default: false,
            required: false,
        },
        textAlign: {
            type: String,
            default: 'left',
            required: false,
        },
        content: {
            type: Object,
            default: () => ({}),
            required: false,
        },
        buttons: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
    },
    computed: {
        allowCustomTextColor() {
            return this.content?.backgroundColor === 'white';
        },
    },
};
