
    import breakpoints from '~/mixins/breakpoints';
    export default {
        name: 'ContentRouter',
        mixins: [ breakpoints ],
        props: {
            data: {
                type: Object,
                required: false,
                default() {
                    return {};
                },
            },
        },
    };
