/* eslint camelcase: 0 */
export default {
    lang: {
        fi: 'Suomi',
        en: 'English',
        sv: 'Ruotsi',
        choose: 'Valitse kieli',
        pathText: 'IN ENGLISH',
    },
    navigation: {
        login: 'Kirjaudu',
        user: 'Käyttäjä',
        search: 'Hae',
        close: 'Sulje',
        cart: 'Ostoskori',
        menu: 'Valikko',
        profile: 'Oma tili',
        homeowner: 'Loma-asuntojen omistajapalvelu',
        logout: 'Kirjaudu ulos',
        toContent: 'Siirry sisältöön',
        cookies: 'Evästeet',
    },
    units: {
        durationHoursMinutes: '{0}h {1}min',
        durationHours: '{0}h',
        durationMinutes: '{0}min',
        time: 'klo.',
    },
    icon: {
        'bathroom': 'Kylpyhuone',
        'bed': 'Sänky',
        'calendar': 'Kalenteri',
        'cart': 'Ostoskärry',
        'checkin': 'Checkin',
        'checklist': 'Tarkistuslista',
        'checkmark': 'Valintamerkki',
        'checkmark-circle': 'Valintamerkki',
        'checkmark-circle-empty': 'Valintamerkki',
        'chevron': 'Nuoli',
        'chevron-up': 'Nuoli ylös',
        'chevron-down': 'Nuoli alas',
        'chevron-right': 'Nuoli oikealle',
        'chevron-left': 'Nuoli vasemmalle',
        'close': 'Sulje',
        'close-circle': 'Sulje',
        'cross': 'Risti',
        'email': 'Sähköposti',
        'error': 'Virhe',
        'euro': 'Euro',
        'facebook': 'Facebook',
        'family': 'Perhe',
        'filter': 'Suodatin',
        'flag-fi': 'Lippu Suomi',
        'flag-sv': 'Lippu Ruotsi',
        'flag-en': 'Lippu Englanti',
        'globe': 'Maapallo',
        'heart': 'Sydän',
        'info': 'Info',
        'instagram': 'Instagram',
        'kitchen': 'Keittiö',
        'list': 'Lista',
        'list-add': 'Lisää listaan',
        'logout': 'Kirjaudu ulos',
        'meals': 'Aterioita',
        'menu': 'Valikko',
        'no': 'Ei',
        'no-circle': 'Ei',
        'pin': 'Naula',
        'phone': 'Puhelin',
        'screen': 'Ruutu',
        'search': 'Haku',
        'share': 'Jaa',
        'slider-arrow': 'Nuoli',
        'slider-arrow-left': 'Nuoli vasemmalle',
        'slider-arrow-right': 'Nuoli oikealle',
        'star': 'Tähti',
        'tennis': 'Tennis',
        'time': 'Aika',
        'terms': 'Ehdot',
        'trash': 'Roskakori',
        'twitter': 'Twitter',
        'user': 'Käyttäjä',
        'user-badge': 'Käyttäjä',
        'vmfooter': 'VM Footer',
        'yes': 'Kyllä',
        'yes-circle': 'Kyllä',
        'youtube': 'Youtube',
    },
    cms: {
        load: 'Lataa lisää',
        next: 'Seuraava',
        previous: 'Edellinen',
        slide: 'Dia',
        open: 'Avaa',
        close: 'Sulje',
    },
    month: {
        0: 'tammikuu',
        1: 'helmikuu',
        2: 'maaliskuu',
        3: 'huhtikuu',
        4: 'toukokuu',
        5: 'kesäkuu',
        6: 'heinäkuu',
        7: 'elokuu',
        8: 'syyskuu',
        9: 'lokakuu',
        10: 'marraskuu',
        11: 'joulukuu',
    },
    cart: {
        text: {
            purchase: 'Ostos',
            purchases: 'Ostokset',
            heading: 'Ostoskori',
            day: 'Päivä',
            days: 'Päivät',
            date: 'Päivämäärä',
            dates: 'Päivämäärät',
            time: 'Aika',
            duration: 'Kesto',
            effect: 'Voimassa',
            persons: 'Henkilöt',
            total: 'Yhteensä',
            noTax: 'Yhteensä ilman alv:a',
            tax: 'ALV',
            checkout: 'Kassalle',
            empty: 'Ostoskorisi on tyhjä',
            remove: 'Poista',
            location: 'Sijainti',
            unavailable: 'Tuote ei ole enää saatavilla. Poista tuote ostoskorista.',
        },
        upgrade: {
            text: 'Haluatko päivittää huonevarauksesi {0} ainoastaan {1}€?',
            button: '+ {0} € Päivitys',
            specs: 'lisätiedot',
            modalButton: 'Vaihda majoituksesi tähän',
            benefits: 'Majoitustyypin edut',
            price: 'Hinta',
            total: '(Yht. {0} €)',
        },
        message: {
            productAdded: 'Tuotteesi on lisätty ostoskoriin',
        },
    },
    product: {
        text: {
            empty: 'Voi harmi, valitettavasti hakuehdoillasi ei löytynyt tuloksia. Kokeile toista ajankohtaa tai tutustu muihin palveluihimme.',
            emptyFennada: 'Voi harmi, valitettavasti hakuehdoillasi ei löytynyt tuloksia. Kokeile toista ajankohtaa tai tutustu muihin majoitusvaihtoehtoihimme. Jos olet varaamassa majoitusta yli kolmelle henkilölle, ota yhteyttä asiakaspalveluumme tai kokeile löytyisikö 2 henkilön huoneita sopiva määrä tarpeisiisi.',
            emptyHotel: 'Voi harmi, valitettavasti hakuehdoillasi ei löytynyt tuloksia. Kokeile toista ajankohtaa tai tutustu muihin majoitusvaihtoehtoihimme. Jos olet varaamassa majoitusta isommalle seurueelle, ota yhteyttä asiakaspalveluumme tai kokeile löytyisikö useampia erillisiä majoituksia sopiva määrä tarpeisiisi.',
            searchManually: 'Päivitä tulokset Hae-painikkeella',
            addToCart: 'Lisää ostoskoriin',
            available: 'Paikkoja',
        },
        message: {
            availabilityFailed: 'Tuotetta ei tällä hetkellä ole saatavilla',
        },
        cancellation: {
            yes: 'Kyllä',
            no: 'Ei',
        },
        lodgings: {
            one: 'Max. 1 henkilö',
            two: 'Max. 2 henkilölle',
            three: 'Max. 3 henkilölle',
            four: 'Max. 4 henkilölle',
            five: 'Max. 5 henkilölle',
            six: 'Max. 6 henkilölle',
            seven: 'Max. 7 henkilölle',
            eight: 'Max. 8 henkilölle',
            nine: 'Max. 9 henkilölle',
            ten: 'Max. 10 henkilölle',
            eleven: 'Max. 11 henkilölle',
            twelve: 'Max. 12 henkilölle',
        },
        filters: {
            time: 'Päivämäärä',
            chosen: '{0} päivää valittu',
            persons: 'Henkilöt',
            personsAmount: 'henkilöä',
            addPersons: 'Lisää henkilöitä',
            person: 'henkilö',
            multiPersons: 'henkilöä',
            adults: 'Aikuiset',
            children: 'Lapset',
            smallChildren: 'Pikkulapset',
            pets: 'Lemmikit',
            adultsByline: '15-vuotiaat tai vanhemmat',
            childrenByline: '5-14-vuotiaat',
            smallChildrenByline: '0-4-vuotiaat',
            petsByline: 'Koirat, kissat',
            priceFrom: 'Hinta alkaen',
            priceTo: 'Hinta enintään',
            equipments: 'Varustelu',
            choose: 'Valitse',
        },
        classification: {
            activity: {
                heading: 'Valitse',
                date: 'Pvm',
                time: 'Aika',
                duration: 'Kesto',
                product: 'Tuote',
                price: 'Hinta',
                free: 'Vapaana',
            },
            course: {
                calendar: {
                    from: 'Alkaen',
                    to: 'Loppuu',
                },
                byline: 'Kurssin varaus',
                heading: 'Kurssivalinta',
                course: 'Kurssi',
                dates: 'Päivämäärät',
                places: 'Paikat',
                total: 'Hinta yht.',
                free: 'Vapaata',
                taken: 'Varattu',
            },
            accommodation: {
                calendar: {
                    from: 'Saapuminen',
                    to: 'Lähteminen',
                },
                byline: 'Huoneen varaus',
                heading: 'Huonevalinta',
                roomType: 'Huonetyyppi',
                cancellation: 'Peruutusoikeus',
                lodging: 'Majoitus',
                total: 'Hinta yht.',
            },
        },
        buttons: {
            reserve: 'Varaa',
            hire: 'Vuokraa',
            availabilityAndPrice: 'Hae',
            update: 'Päivitä',
            addToCart: 'Lisää ostoskoriin',
            buy: 'Osta',
            comfort: 'Mukavuudet',
        },
    },
    productSearch: {
        header: 'Aloita matkasi huipulle',
        tabs: {
            accommodation: 'Varaa majoitus',
            course: 'Varaa kurssi',
            activities: 'Varaa aktiviteetit ja palvelut',
            giftcard: 'Osta lahjakortti',
        },
        texts: {
            button: 'Hae',
            buttonSelect: 'Osta lahjakortti',
            selectGiftcardSum: 'Valitse lahjakortin summa',
            chose: 'Valitse',
        },
    },
    productHeader: {
        roomsPrice: 'Huoneiden hinnat alk.',
        coursePrice: 'Kurssin hinta alk.',
        giftcardPrice: 'Lahjakortit alk.',
        night: 'yö',
    },
    productDetails: {
        checkin: 'Sisäänkirjautuminen',
        checkout: 'Uloskirjautuminen',
        online: 'Online check-in',
        meals: 'Ruokailu',
        terms: 'Varaus- ja peruutusehdot',
        amenities: 'Mukavuudet',
        room_type_labels: 'Nukkuminen',
        amenity_labels: 'Mukavuudet',
        bathroom_labels: 'Kylpyhuone',
        kitchen_labels: 'Keittiö',
        exampleProgram: 'Malliohjelma',
        education: {
            coursePeriod: 'Hakuaika',
            courseLength: 'Opintojen kesto',
            price: 'Opintojen hinta',
            competencePoints: 'Tutkinnon laajuus',
            competencePointsText: 'osaamispistettä',
            degreeTitle: 'Tutkintonimike',
            courseContact: 'Lisätiedot',
        },
        course: {
            period: 'Ajankohta',
            length: 'Kesto',
            price: 'Hinta',
            program: 'Kurssiohjelma',
            meals: 'Ruokailu',
            accommodation: 'Majoitus',
        },
        activity: {
            availability: 'Saatavuus',
            price: 'Hinta',
        },
        giftcard: {
            effect: 'Voimassaolo',
            price: 'Hinta',
            usage: 'Käyttö',
            terms: 'Myyntiehdot',
        },
    },
    productTable: {
        yes: 'Kyllä',
        no: 'Ei',
    },
    productCard: {
        additionalInfo: 'Lisätietoa tunnista',
        reservationInfo: 'Varaa Vierumäki Friends -sovelluksella',
        startPrice: 'Alk.',
        priceStart: 'Hinnat alkaen',
        night: 'yö',
        checkPictures: 'Katso kuvat',
        seeMoreInfo: 'Varaa',
        showAlternative: 'Näytä vaihtoehdot',
        hiddenAlternative: 'Piilota vaihtoehdot',
        noOptionsText: 'Ei saatavilla',
        cancelable: 'Peruutusoikeus',
        notCancelable: 'Ei peruutusoikeutta',
        closeBtnText: 'Sulje',
    },
    login: {
        text: {
            logout: 'Kirjaudu ulos',
            logoutByline: 'Olet jo kirjautunut sisään, haluatko kirjautua ulos?',
        },
        message: {
            signoff: 'Olet nyt kirjautunut ulos',
            logoutFailed: 'Uloskirjautuminen epäonnistui',
            verify: 'We sent you email. Please go click and verify. You need to verify and so on...',
            signon: 'Olet kirjautunut sisään',
            failed: 'Kirjautuminen epäonnistui',
            redirect: 'Uudelleenohjataan',
        },
        fields: {
            email: 'Sähköposti',
            password: 'Salasana',
            rememberMe: 'Muista minut',
            forgotPassword: 'Unohditko salasanasi?',
            registerLink: 'UUSI ASIAKAS? Rekisteröidy tästä',
            loginButton: 'Kirjaudu sisään',
        },
        status: {
            loggedIn: 'Kirjautunut',
        },
    },
    registration: {
        fields: {
            firstName: 'Etunimi',
            lastName: 'Sukunimi',
            phone: 'Puhelinnumero',
            email: 'Sähköpostiosoite',
            password: 'Salasana',
            confirmPassword: 'Salasana uudestaan',
            newsLetter: 'Tilaan uutiskirjeen ja hyväksyn ehdot.',
            friends: 'Rekisteröitymällä liityn Vierumäki Friends -etuohjelmaan ja hyväksyn ehdot.',
            registerButton: 'Luo tili',
            backLink: 'Oletko jo rekisteröitynyt? Kirjaudu sisään',
        },
        message: {
            success: 'Rekisteröinti onnistui',
            failed: 'Rekisteröinti epäonnistui',
            fieldsFailed: 'Sähköpostiosoitteesi ja puhelinnumerosi on jo rekisteröity, voit jatkaa varaustasi sisäänkirjautumisen kautta. Jos olet unohtanut salasanan, saat sen nollattua ',
            emailFailed: 'Sähköpostiosoitteesi on jo rekisteröity, voit jatkaa varaustasi sisäänkirjautumisen kautta. Jos olet unohtanut salasanan, saat sen nollattua ',
            phoneFailed: 'Puhelinnumerosi on jo rekisteröity, voit jatkaa varaustasi sisäänkirjautumisen kautta. Jos olet unohtanut salasanan, saat sen nollattua ',
            verificationSuccess: 'Verification done. You can now log in.',
            verificationFailed: 'Verification failed.',
            here: 'täältä.',
        },
    },
    user: {
        details: {
            heading: 'Asiakastiedot',
            changePassword: 'Vaihda salasana',
            changeDetails: 'Muokkaa asiakastietoja',
        },
        newsLetter: {
            heading: 'Tilaa uutiskirje',
            byline: 'Hyppää mukaan #matkallahuipulle ja tilaa uutiskirjeemme. Kuulet säännöllisesti ensimmäisten joukossa ajankohtaisia kuulumisia Vierumäeltä ja saat parhaat tarjoukset suoraan sähköpostiisi. Voit myös milloin tahansa lopettaa uutiskirjeen tilauksen.',
        },
        fields: {
            firstName: 'Etunimi',
            lastName: 'Sukunimi',
            email: 'Sähköposti',
            phone: 'Puhelin',
            address: 'Postiosoite',
            zipCode: 'Postinumero',
            city: 'Kaupunki',
            back: 'Peruuta',
            submit: 'Tallenna',
        },
        messages: {
            updateSuccess: 'Tiedot tallennettu',
            updateSuccessBut: 'Tiedot tallennettu',
            updateFail: 'Tietojen tallennus epäonnistui',
            noData: 'Käyttäjätiedot ei löytynyt',
        },
    },
    resetPassword: {
        text: {
            emailSent: 'Meiltä on lähtenyt sinulle sähköpostia',
            checkEmail: 'Tarkistathan sähköpostisi',
            renewPassword: 'Luo uusi salasanasi tässä',
            renewPasswordByline: 'Alla voit asettaa uuden salasanan',
        },
        fields: {
            email: 'Sähköposti',
            newPassword: 'Uusi salasana',
            confirmationPassword: 'Toista uusi salasana',
            send: 'Palauta salasana',
            login: 'Kirjaudu sisään',
            save: 'Tallenna',
        },
        message: {
            success: 'Salasanan vaihtaminen onnistui',
            emailFailed: 'Salasanan vaihtaminen epäonnistui',
            passwordSuccess: 'Salasana päivitetty',
            passwordFailed: 'Salasanan uudistus epäonnistui',
        },
    },
    verifyEmail: {
        text: {
            heading: 'Verify your email',
            text: 'Verify your email',
        },
        buttons: {
            reSend: 'Resend code',
            submit: 'Verify email',
        },
        message: {
            failed: 'Verification failed',
            success: 'Verification success',
        },
    },
    form: {
        texts: {
            mandatoryFields: 'Tämä tieto on pakollinen: *',
        },
        imageSelect: {
            selected: 'Valittu',
            unselected: 'Valitse',
        },
        fields: {
            selectDefault: 'Valitse',
        },
        error: {
            invalid: 'Täytäthän kaikki pakolliset kentät',
            empty: 'Tämä kenttä on pakollinen',
            email: 'Täytäthän sähköpostiosoite',
            phone: 'Täytäthän puhelinnumerosi',
            equals: 'Kentät ei täsmää',
            minlength: 'Kentän merkkimäärä vähintään {0}',
            maxlength: 'Kentän merkkimäärä enintään {0}',
            pattern: 'Salasana sisältää numero, pieni ja iso kirjain sekä .!@#$%^&*)/\\(+=._-',
        },
    },
    calendar: {
        filter: 'Suodata tapahtumia',
        add: 'Lisää kalenteriin',
        today: 'Tänään',
        noEvents: 'Tälle päivälle ei ole lainkaan merkittyjä tapahtumia.',
        error: 'Kalenterin hakeminen epäonnistui',
        loading: 'Ladataan kalenteria',
    },
    event: {
        duration: 'Kesto',
        description: 'Tunnin kuvaus',
        date: 'Ajankohta',
        location: 'Sijainti',
        mapLocation: 'Sijainti kartalla',
        addCalendar: 'Tallenna kalenteriin',
        hours: '0 tuntia | {n} tunti | {n} tuntia',
        minutes: '0 minuuttia | {n} minuutti | {n} minuuttia',
        message: {
            failed: 'Virhe kalenteritiedoston luonnissa',
        },
    },
    content: {
        message: {
            loadFailed: 'Sivun sisällön lataaminen epäonnistui',
            errorTitle: 'Valitsemaasi sivua ei löydy',
            errorMessage: 'Voi hyvänen aika! Tämä on se error 404-sivu.',
            notFoundMessage: [
                'Tällä kertaa etsimääsi sivua ei valitettavasti löytynyt. Tarkistathan, että osoite on nyt varmasti oikea. Kyseinen sivu on myös voitu poistaa, tai sen sijainti on voinut muuttua, ja tällöin syyttävä sormi kohdistuu meihin. Pahoittelut turhasta klikkauksesta!',
                'Toivottavasti jaksat yrittää uudestaan, kuten Lasse Virén kaaduttuaan Münchenin olympiakisojen 10.000 metrin loppukilpailussa ja painaa esimerkiksi tästä päästäksesi takaisin etusivulle.',
                'Huikeaa päivää!',
            ],
            homePageBtn: 'Siirry etusivulle',
            serverErrorTitle: 'Palvelinvika',
            serverErrorMessage: '500',
            serverErrorDescription: '',
        },
    },
    checkout: {
        breadcrumbs: {
            orderInfo: 'Tilauksen tiedot',
            paymentInfo: 'Maksutiedot',
            confirm: 'Tilausvahvistus',
        },
        status: {
            processing: 'Tilausta käsitellään',
            error: 'Tilauksessasi tapahtui virhe.',
            accepted: 'Tilauksesi on hyväksytty!',
            paymentCancelled: 'Maksutapahtuma on peruutettu',
            paymentTimedOut: 'Maksutapahtuma aikakatkaistiin',
            paymentFailed: 'Maksutapahtuma epäonnistui',
            paymentExpired: 'Ostoskorisi sisältö on vanhentunut – aloitathan varauksesi alusta',
            processingIcon: 'Tilaustasi käsitellään, odota hetki',
        },
        texts: {
            hello: 'Hei',
            return: 'Takaisin etusivulle',
            edit: 'Muokkaa',
            button: 'Jatka maksutavan valintaan',
            orderConfirmed: 'Lähetämme tilausvahvistuksen ja yhteenvedon myös sähköpostiisi',
            info: 'Tilauksen tiedot',
            orderNumber: 'Tilausnumero',
            orderDate: 'Tilauspäivä',
            product: 'Tuote',
            products: 'Tuotteet',
            orderer: 'Tilaaja',
            ordererInformation: 'Tilaajan tiedot',
            cardInfo: 'Kortin tiedot',
            email: 'Sähköpostiosoite',
            phone: 'Puhelinnumero',
            guests: 'Vieraat',
            paymentMethod: 'Maksutapa',
            delete: 'Poista',
            billingAddress: 'Laskutusosoite',
            seller: 'Myyvä yritys',
            businessId: 'Y-tunnus',
            yourOrder: 'Tilatut tuotteet',
            recipientName: 'Saaja',
            recipientEmail: 'Toimitusosoite',
            message: 'Viesti lahjakortin saajalle',
            time: 'Ajankohta',
            effect: 'Voimassa',
            persons: 'Henkilöt',
            summary: 'Yhteenveto',
            totals: 'Yhteensä',
            discountCode: 'Etukoodi',
            discount: 'Alennus',
            taxFreeTotal: 'Yhteensä ALV 0%',
            vat: 'ALV',
            thankText: 'Kiitos asioinnista - viihtyisää Vierumäkeä',
            customerService: 'Vierumäen asiakaspalvelu',
            orderInfo: 'Lisätiedot',
            stopPayment: 'Keskeytä maksaminen',
            guest: 'Vieras',
            child: 'Lapsi',
            smallChild: 'Pikkulapsi',
            adult: 'Aikuinen',
            firstName: 'Etunimi',
            lastName: 'Sukunimi',
            pageTitle: 'Checkout',
            unregisteredTitle: 'Rekisteröityminen tuo etuja',
            unregisteredDescription: 'Rekisteröitymällä Vierumäen asiakkaaksi saat säännöllisesti valikoituja etuja ja tarjouksia sekä asioit jatkossa meillä nopeammin ja sujuvammin. Rekisteröityminen vaatii vahvistuksen sähköpostilla.',
            unregisteredCheckbox: 'Kyllä, rekisteröidyn samalla asiakkaaksi.',
            unregisteredMarketingEmail: 'Tilaan Vierumäen uutiskirjeen, joka ilmestyy noin kerran kuussa.',
            unregisteredConfirm: 'Hyväksyn Vierumäen {reservation} sekä henkilötietojen käsittelyn {privacypolicy} mukaisesti.*',
            unregisteredReservationLinkText: 'varaus- ja peruutusehdot',
            unregisteredReservationLinkAddress: '/',
            unregisteredPrivacyPolicyLinkText: 'tietosuojaselosteen',
            unregisteredPrivacyPolicyLinkAddress: '/',
        },
        incomplete: {
            guestInfo: 'Tilauksen tiedot',
            additional: 'Tilaukseen liittyvät lisätiedot',
            label: 'Lisätiedot',
            helpText: 'Esim. kyselyt lemmikkihuoneista, allergiat, erityistoiveet yms.',
            accommodation: 'Majoitus',
            courses: 'Kurssit',
        },
        message: {
            submitFailed: 'Tilausta ei voitu käsitellä',
        },
    },
    search: {
        fields: {
            heading: 'Hae sivustolta',
            button: 'Hae',
            placeholder: 'Haku',
        },
        results: {
            none: 'Voi harmi, valitettavasti hakuehdoillasi ei löytynyt tuloksia.<br /><br />Älä luovuta vielä, varmista oikeinkirjoitus tai kokeile toista hakusanaa.<br />Voit myös siirtyä tästä suoraan <a href="/">etusivu</a>, <a href="/info">yhteystietoihin</a>, <a href="/majoitushaku">majoitushakuun</a> tai <a href="/aktiviteettikauppa">aktiviteettikauppaan</a>.',
            products: 'Tuotteet ja palvelut',
            showing: 'Näytetään tulokset {0} - {1} / {2}',
            withTerm: 'Hakutulokset sanalla: {0}',
            price: 'Alk. {0} €',
        },
    },
    discount: {
        texts: {
            heading: 'Etukoodi',
            discountCodeLabel: 'Etukoodi',
            add: 'Lisää',
            remove: 'Poista',
            link: 'Onko sinulla etukoodi?',
            submitted: 'Etukoodi lisätty',
        },
    },
    category: {
        text: {
            reserve: 'Varaa',
        },
        heading: {
            court: 'Valitse kenttä',
            accessory: 'Valitse väline',
            accommodation: 'Etsi majoitusta',
            education: 'Etsi koulutusta',
            course: 'Etsi kursseja',
            default: 'Etsi',
        },
        table: {
            description: 'Tuntikuvaus',
            location: 'Sijainti',
            close: 'Sulje',
            removeFilters: 'Poista kaikki valinnat',
        },
        listing: {
            up: 'Takaisin ylös',
            show: 'Näytetään',
            products: 'tuotetta',
            noResults: 'Voi harmi, valitettavasti haulla ei löytynyt tuloksia. Kokeile muuttaa hakuehtoja.',
        },
        filters: {
            remove: 'Poista kaikki valinnat',
            price: 'Hinta',
        },
        popup: {
            open: 'Piilota suodattimet',
            closed: 'Rajaa tulokset',
        },
    },
    cookie: {
        title: 'Hallinnoi evästeitä',
        description: 'Vierumäki käyttää evästeitä sivuston toiminnan mahdollistamiseen, käyttökokemuksen parantamiseen ja mainonnan kohdentamiseen. Hyväksymällä evästeet autat meitä parantamaan verkkopalveluamme käyttäjäystävällisemmäksi. Voit myös peruuttaa suostumuksesi tai muuttaa sitä milloin tahansa klikkaamalla sivun alapalkista kohtaa ”Evästeet”.',
        readMore: 'Lue lisää evästeiden käytöstä täällä.',
        readMoreLinkText: 'Lue lisää evästeiden käytöstä',
        acceptAll: 'Hyväksy kaikki',
        acceptAllLong: 'Hyväksy kaikki evästeet',
        acceptSelected: 'Hyväksy valitut',
        acceptSelectedLong: 'Hyväksy valitut evästeet',
        changeSettings: 'Muokkaa evästeitä',
        changeSettingsLong: 'Muokkaa evästeasetuksia',
        back: 'Takaisin',
        privacyInfo: 'Tietosuojatiedot',
        alwaysActive: 'Aina aktiivinen',
        essential: {
            title: 'Välttämättömät evästeet – Necessary cookies',
            text: 'Verkkosivun toimivuuden kannalta välttämättömät evästeet.',
            accept: 'Hyväksyn välttämättömät evästeet',
        },
        functional: {
            title: 'Käyttökokemuksen parantaminen – Functional cookies',
            text: 'Verkkosivun kehittämiseen tarkoitetut evästeet.',
            accept: 'Hyväksyn toiminnalliset evästeet',
        },
        analytics: {
            title: 'Analytiikkaevästeet',
            text: 'Näiden evästeiden avulla keräämme tietoja vierailuista ja liikenteen lähteistä. Käytämme niitä mittaamiseen ja verkkosivuston käyttökokemuksen parantamiseen.',
            accept: 'Hyväksyn analyyttiset evästeet',
        },
        marketing: {
            title: 'Tarpeitasi vastaava markkinointi – Marketing cookies',
            text: 'Verkkosivun käyttäjien toiminnan tarkastelu ja käyttäjien muistaminen myöhempää vierailua varten.',
            accept: 'Hyväksyn markkinointievästeet',
        },
        settings: 'Evästeasetukset',
        updateSuccess: 'Asetukset päivitettiin onnistuneesti',
        cookies: 'Evästeet',
        cookieTitle: 'Valitse, mitä evästeitä käytät',
    },
    articles: {
        notFound: 'Artikkeleita ei löytynyt.',
        showArticles: 'tiedotetta',
    },
    giftcard: {
        title: 'Vierumäki lahjakortti',
        formTitle: 'Lahjakortin valinnat',
        buy: 'Osta',
        warning: {
            back: 'Peruuta',
            heading: 'Ostoskorin tyhjentäminen',
            mainHeading: 'Lahjakorttiosto vaatii ostoskorin tyhjentämisen',
            text: 'Et voi valitettavasti ostaa lahjakorttia yhdessä muiden tuotteiden tai palveluiden kanssa. Jatkaaksesi asiointia, tyhjennä ostoskori ensin. Lahjakortin oston jälkeen voit palata muiden ostostesi pariin.',
            button: 'Tyhjennä ostoskori ja jatka maksamaan',
        },
        fields: {
            value: 'Lahjakortin arvo',
            name: 'Lahjakortin saajan nimi',
            message: 'Viesti kortin saajalle (max. 150 merkkiä)',
            email: 'Sähköpostiosoite, johon lahjakortti lähetetään',
            confirmEmail: 'Vahvista sähköpostiosoite',
            image: 'Valitse kuva',
            submit: 'Osta lahjakortti',
        },
    },
    loginModal: {
        texts: {
            back: 'Takaisin',
            heading: 'Kirjaudu / Rekisteröidy',
            loginHeading: 'Kirjaudu sisään',
            loginText: 'Kirjaudu sisään Vierumäki-tunnuksillasi, niin maksaminen hoituu nopeammin.',
            loginButton: 'Kirjaudu',
            continue: 'Jatka',
            registerLoginDescription: 'Rekisteröitymällä saat säännöllisesti etuja ja tarjouksia sekä asioit jatkossa sujuvammin. Rekisteröityminen vaatii vahvistuksen sähköpostitse.',
            registerLink: 'Rekisteröidy asiakkaaksi',
            registerThanksHeader: 'Kiitos rekisteröitymisestä!',
            registerThanksConfirmation: 'Lähetimme vahvistuksen tilin luomisesta osoitteeseen: ',
            registerThanksConfirmation2: 'Vahvista vielä tili sähköpostiin lähetetyn linkin kautta voidaksesi jatkaa.',
            registerFail: 'Rekisteröityminen epäonnistui',
            unregisteredHeading: 'Jatka kirjautumatta',
            unregisteredText: 'Jos jatkat kirjautumatta, emme talleta sinulle tietoja ostostasi etkä voi hyödyntää maksutietoja seuraavalla kerralla.',
            unregisteredDescription: 'Täytettyäsi tilaukseen tarvittavat tiedot pääset jatkamaan asiointiasi kassalla.',
            registrationDescription: 'Olet rekisteröitymässä sähköpostilla:',
            registrationHelp: 'Aseta tilillesi salasana',
            unregisteredButton: 'Jatka kirjautumatta',
            givePassword: 'Anna tilin salasana',
        },
        fields: {
            firstName: 'Etunimi',
            lastName: 'Sukunimi',
            mobileNumber: 'Puhelinnumero',
            email: 'Sähköposti',
            terms: 'Hyväksyn lahjakortin ehdot',
            termsLink: 'Tutustu ehtoihin',
            marketingEmail: 'Tilaan Vierumäen uutiskirjeen',
            continue: 'Jatka maksamaan',
        },
    },
    giftcardModal: {
        texts: {
            back: 'Takaisin',
            title: 'Lahjakortin tiedot',
            heading: 'Muokkaa lahjakortin tietoja',
        },
        fields: {
            name: 'Saajan nimi',
            message: 'Viesti lahjakortin saajalle',
            email: 'Toimitusosoite',
            submit: 'Tallenna',
        },
    },
    buyerInfo: {
        texts: {
            heading: 'Ostajan tiedot',
            edit: 'Muokkaa',
        },
    },
    accessibility: {
        fields: {
            name: 'Nimi',
            email: 'Sähköpostiosoite',
            feedback: 'Saavutettavuuspalaute',
            send: 'Lähetä',
        },
        message: {
            sent: 'Palaute lähetetty',
            failed: 'Palautteen lähettäminen epäonnistui',
            captchaFailed: 'Varmistus epäonnistui',
        },
    },
    alert: {
        close: 'Sulje',
        closeMessage: 'Sulje hälytysviesti',
    },
    newsletter: {
        fields: {
            firstName: 'Etunimi',
            lastName: 'Sukunimi',
            email: 'Sähköpostiosoite',
            companyNewsletter: 'Olen kiinnostunut yrityksille suunnatusta viestinnästä',
            terms: 'Hyväksyn tietojeni käsittelyn tietosuojaselosteen mukaisesti',
            send: 'Lähetä',
            termsLink: 'Tutustu ehtoihin',
        },
        message: {
            success: 'Uutiskirjeen tilaus onnistui',
            failed: 'Uutiskirjeen tilaus epäonnistui',
        },
    },
    map: {
        title: 'Näytä kartalla',
        all: 'Kaikki',
        filter: 'Suodatin',
        tags: {
            all: 'Kaikki',
            accommodation: 'Majoitus',
            attraction: 'Nähtävyys',
            caddiemaster: 'Caddiemaster',
            cafe: 'Kahvila',
            equipmentrental: 'Välinevuokraus',
            gamesarea: 'Liikuntapaikat',
            info: 'Info',
            meetingroom: 'Kokoustila',
            outdooractivity: 'Ulkoaktiviteetti',
            parking: 'Pysäköinti',
            pointofinterest: 'Point of interest',
            reception: 'Vastaanotto',
            restaurant: 'Ravintola',
            wellness: 'Hyvinvointi',
            accommodationapartments: 'Loma-asunnot',
            accommodationbudget: 'Sporttimajoitus',
            accommodationcottages: 'Mökit',
            accommodationhotels: 'Hotellit',
            conferenceroom: 'Kokoustilat',
            other: 'Muut',
            spa: 'Hyvinvointi',
        },
    },
    educationList: {
        price: 'Koulutuksen hinta',
        free: 'Perustutkintokoulutus on maksutonta',
        emptySearchResult: 'Hakuasi vastaavia koulutuksia ei löytynyt. Kokeile muuttaa hakukriteerejä.',
        removeAllFilters: 'Poista kaikki valinnat',
    },
    educationPage: {
        basicInformation: 'Perustiedot',
        degreeExtent: 'Tutkinnon laajuus',
        degreeStartTime: 'Koulutus alkaa',
        degreeLength: 'Koulutuksen kesto',
        degreePrice: 'Koulutuksen hinta',
        degreeImplementation: 'Toteutustapa',
    },
    educationPaymentPage: {
        choosePaymentOption: 'Valitse haluamasi maksuvaihtoehto',
        wholeEducation: 'Koko koulutus',
        payInParts: 'Maksu jaksoittain',
        choosePaymentPeriods: 'Valitse haluamasi maksujaksot:',
        addToCart: 'Lisää ostoskoriin',
    },
    profile: {
        pageTitle: 'Oma tili',
        title: 'Oma tili',
    },
    homeowner: {
        pageTitle: 'Loma-asuntojen omistajapalvelu',
        hi: 'Hei',
        owner: 'omistaja',
        select: 'Valitse loma-asunto',
        selectText: 'Valitse',
        bookable: 'Käyttöoikeus ajankohtasi',
        effective: 'Voimassa olevat varauksesi',
        time: 'Aika',
        type: 'Varaustyyppi',
        bookableTime: 'Käyttövaraus',
        ownBookableTime: 'Oma majoitusvaraus',
        edit: 'Muokkaa',
        delete: 'Poista',
        buttonBookable: 'Varattavissa oleva aika',
        buttonOwn: 'Oma majoitusvaraus',
        message: {
            reservationFailed: 'Varaus epäonnistui',
        },
    },
};
