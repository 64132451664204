
import { mapMutations, mapGetters } from 'vuex';
import breakpoints from '~/mixins/breakpoints';

export default {
    name: 'CookieConsent',
    mixins: [breakpoints],
    data() {
        return {
            step: 0,
            value: {
                essential: true,
                functional: true,
                analytics: true,
                marketing: true,
            },
            showCookieConsentInit: false,
            accepted: [],
        };
    },
    computed: {
        ...mapGetters({
            getCookieConsent: 'navigation/getCookieConsent',
        }),
        linkUrl() {
            if (this.$i18n.locale === 'en') {
                return '/privacy-policy';
            } else if (this.$i18n.locale === 'se') {
                return '/cookies';
            }

            return '/evasteet-ja-niiden-kayttotarkoitukset';
        },
        privacyPolicyLink() {
            return 'https://mailchimp.vierumaki.fi/ohjelmat/Tietosuojaseloste_Vierumaki_02062021.pdf';
        },
        cookiesPageLink() {
            return 'https://mailchimp.vierumaki.fi/ohjelmat/Verkkosivujen%20kayttoehdot_ja%20evastekaytannot_Mag%2007062021.pdf';
        },
        items() {
            return [
                {
                    code: 'essential',
                    title: this.$t('cookie.essential.title'),
                    required: true,
                    text: this.$t('cookie.essential.text'),
                    value: this.value.essential,
                },
                {
                    code: 'functional',
                    title: this.$t('cookie.functional.title'),
                    required: false,
                    text: this.$t('cookie.functional.text'),
                    value: this.value.functional,
                    // value: this.setSelection('functional', this.value.functional),
                },
                // {
                //     code: 'analytics',
                //     title: this.$t('cookie.analytics.title'),
                //     required: false,
                //     text: this.$t('cookie.analytics.text'),
                //     value: this.value.analytics,
                // },
                {
                    code: 'marketing',
                    title: this.$t('cookie.marketing.title'),
                    required: false,
                    text: this.$t('cookie.marketing.text'),
                    value: this.value.marketing,
                    // value: this.setSelection('marketing', this.value.marketing),
                    url: true,
                },
            ];
        },
        showCookieConsent: {
            get() {
                return this.getCookieConsent ?? this.showCookieConsentInit;
            },
            set(state) {
                return state;
            },
        },
    },
    mounted() {
        this.accepted = this.$cookies.get('AcceptedCookies');

        if (!this.accepted) {
            this.setCookieConsentActive(true);
            this.showCookieConsent = true;
        } else if (this.accepted.includes('functional')) {
            this.$gtm.init(this.$store.$config.gtm.id);
        }
    },
    methods: {
        ...mapMutations({
            setCookieConsentActive: 'navigation/setCookieConsentActive',
        }),
        submit() {
            const copy = JSON.parse(JSON.stringify(this.items));
            const accepted = this.filterAndInitGtm(copy);
            if (!accepted.includes('essential')) {
                accepted.push('essential');
            }
            this.$cookies.set('AcceptedCookies', JSON.stringify(accepted), {
                maxAge: 2592000,
                sameSite: 'Lax',
            });
            this.close();
        },
        close() {
            this.showCookieConsent = false;
            this.setCookieConsentActive(false);
        },
        filterAndInitGtm(copy) {
            return copy.filter(item => item.value)
                .reduce((acc, curr) => {
                    if (curr.code === 'functional') {
                        this.$gtm.init(this.$store.$config.gtm.id);
                    }
                    acc.push(curr.code);

                    return acc;
                }, []);
        },
        acceptAll() {
            const temp = this.items.map(item => ({ ...item, value: true }));
            const copy = JSON.parse(JSON.stringify(temp));
            const accepted = this.filterAndInitGtm(copy);

            this.$cookies.set('AcceptedCookies', JSON.stringify(accepted), {
                maxAge: 2592000,
                sameSite: 'Lax',
                domain: `${'.'.concat(window.location.host.match(/[^.]+\.[^.]{2,3}$/) ? window.location.host.match(/[^.]+\.[^.]{2,3}$/)[0] : window.location.host.split(':')[0])}`,

            });
            this.close();
        },
        all() {
            this.step = 0;
        },
        edit() {
            this.step = 1;
        },
        valueChange(code, value) {
            this.value[code] = value;
        },
        setSelection(code, value) {
            if (this.accepted.length > 0) {
                if (this.accepted.includes(code)) {
                    return this.valueChange(code, value);
                }

                return this.valueChange(code, false);
            }

            return this.valueChange(code, true);
        },
    },
};
