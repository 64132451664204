import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=4346e09b&scoped=true"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"
import style0 from "./Header.vue?vue&type=style&index=0&id=4346e09b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4346e09b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AlertBanner: require('/build/src/components/blocks/AlertBanner.vue').default,VMIcon: require('/build/src/components/elements/VMIcon.vue').default,MegaMenu: require('/build/src/components/layout/MegaMenu.vue').default,Search: require('/build/src/components/layout/Search.vue').default,Cart: require('/build/src/components/layout/Cart.vue').default,CheckoutNav: require('/build/src/components/layout/CheckoutNav.vue').default,MobileNav: require('/build/src/components/layout/MobileNav.vue').default,Header: require('/build/src/components/layout/Header.vue').default})
