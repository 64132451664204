
    export default {
        name: 'Media',
        props: {
            fields: {
                type: Object,
                required: false,
                default: () => {},
            },
        },
    };
