import { render, staticRenderFns } from "./ProductPage.vue?vue&type=template&id=6d335348&scoped=true"
import script from "./ProductPage.vue?vue&type=script&lang=js"
export * from "./ProductPage.vue?vue&type=script&lang=js"
import style0 from "./ProductPage.vue?vue&type=style&index=0&id=6d335348&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d335348",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EducationPaymentPage: require('/build/src/components/blocks/EducationPaymentPage.vue').default,BreadCrumbs: require('/build/src/components/layout/BreadCrumbs.vue').default,ProductHeader: require('/build/src/components/blocks/ProductHeader.vue').default,ProductIllustration: require('/build/src/components/blocks/ProductIllustration.vue').default,ProductDetails: require('/build/src/components/blocks/ProductDetails.vue').default,ProductItems: require('/build/src/components/blocks/ProductPage/ProductItems.vue').default,CategoryItems: require('/build/src/components/blocks/ProductPage/CategoryItems.vue').default,EducationExtra: require('/build/src/components/blocks/ProductDetails/EducationExtra.vue').default,ProductGiftcardOrder: require('/build/src/components/blocks/ProductGiftcardOrder.vue').default,ProductMap: require('/build/src/components/elements/ProductMap.vue').default})
