
export default {
    props: {
        product: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        type: {
            type: String,
            required: false,
            default: 'card',
            validator(value) {
                return ['card', 'slider-card', 'product'].includes(value);
            },
        },
        cardSize: {
            type: String,
            required: false,
            default: 'large',
            validator(value) {
                return ['small', 'large'].includes(value);
            },
        },
        products: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        customPrice: {
            type: [Number, String, Boolean],
            required: false,
            default: false,
        },
    },
    computed: {
        classification() {
            return this.product?.product?.classification ?? 'Accommodation';
        },
        image() {
            if (this.product?.product?.mainImage && 'url' in this.product?.product?.mainImage) {
                return this.$util.parseImage(this.product.product.mainImage, 1);
            }

            return false;
        },
        getLinkElement() {
            return { path: this.product.product?.attributes?.product_pagePath };
        },
        hasOverlay() {
            return this.product?.product?.classification === 'Active';
        },
        hasPagePath() {
            return this.product.product?.attributes?.product_pagePath?.length;
        },
        name() {
            return this.product?.product?.attributes?.marketing_name
                ? this.product?.product?.attributes?.marketing_name
                : this.product?.product?.name
                    ? this.product.product?.name
                    : this.product?.product?.attributes?.erp_product_name ?? '';
        },
        tags() {
            return ['product_label1', 'product_label2', 'product_label3'].map(v => ({
                title: this.product?.product?.attributes ? this.product.product.attributes[v] : '',
            })).filter(v => !!v.title);
        },
        longName() {
            return this.name?.length > 50;
        },
        componentTag() {
            return this.hasPagePath
                ? 'LinkElement'
                : 'div';
        },
    },
    methods: {
        productHas(property) {
            if (!this.product?.product?.attributes || typeof this.product.product.attributes !== 'object') return false;

            return property in this.product.product.attributes && this.product.product.attributes[property];
        },
    },
};
