
import fieldMixin from '~/mixins/widget';
import breakpoints from '~/mixins/breakpoints';

export default {
    mixins: [fieldMixin, breakpoints],
    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
        backgroundColor: {
            type: String,
            required: true,
            default: '',
        },
        textColor: {
            type: String,
            required: true,
            default: 'black',
        },
        textAlign: {
            type: String,
            required: false,
            default: 'left',
        },
        cards: {
            type: Array,
            required: false,
            default: () => [],
        },
        seeMoreLink: {
            title: {
                type: String,
                required: true,
            },
            url: {
                type: String,
                required: true,
            },
            appearance: {
                type: String,
                required: false,
            },
            type: [Object, Boolean],
            required: false,
            default: false,
        },
    },
    data() {
        const show = 1;

        return { show };
    },
    computed: {
        // Number of cards shown in current breakpoint
        cardMax() {
            const mobileMax = 4;
            const tabletMax = 8;

            if (this.atMostMobile) {
                return mobileMax;
            } else if (this.atMostTablet) {
                return tabletMax;
            }

            return Infinity;
        },
    },
};
