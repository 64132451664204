
import { mapGetters, mapMutations } from 'vuex';

export default {
    props: {
        mainImage: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        images: {
            type: [Array, Object],
            required: false,
            default: () => ({}),
        },
        product: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        cheapestPrice: {
            type: [Number],
            required: false,
            default: 0,
        },
        mainTitle: {
            type: String,
            required: false,
            default: '',
        },
        cheapestPriceDiscount: {
            type: [Number],
            required: false,
            default: 0,
        },
        name: {
            type: String,
            required: false,
            default: '',
        },
        mainCard: {
            type: Boolean,
            required: false,
            default: false,
        },
        roomOption: {
            type: [Array, Object],
            required: false,
            default: () => ({}),
        },
    },

    data() {
        return {
            optionsSelect: [],
        };
    },
    computed: {
        ...mapGetters({
            content: 'product/getAvailability',
            fromDate: 'product/getFromDate',
            toDate: 'product/getToDate',
            personCount: 'product/getPersonCount',
            persons: 'product/getPersons',
            totalsLoading: 'cart/getTotalsLoading',
        }),
        getLinkElement() {
            return { path: this.product?.product?.attributes?.product_pagePath };
        },
        hasProduct() {
            return Object.keys(this.product).length !== 0;
        },
        hotelOverview() {
            return this.product?.product?.attributes?.category_page_description;
        },
        isAvailableRooms() {
            const available = this.product?.rooms?.find(room => room[0].available === true);

            return !!available;
        },
        optionTitle() {
            return this.roomOption[0]?.product?.attributes?.marketing_name;
        },
        optionHotelOverview() {
            return this.roomOption[0]?.product?.attributes?.room_type_description;
        },
        optionMainImage() {
            if (this.roomOption[0]?.product?.mainImage && 'url' in this.roomOption[0]?.product?.mainImage) {
                return this.$util.parseImage(this.roomOption[0]?.product.mainImage);
            }

            return false;
        },
        optionImages() {
            const imageUrls = [];
            const product = this.roomOption[0]?.product;
            const { attributes } = product;
            imageUrls.push(this.$util.parseImage(product.mainImage));
            let i = 1;
            while (!!attributes[`secondary_image${i}`]) {
                const v = attributes[`secondary_image${i}`];
                imageUrls.push(this.$util.parseImage(v));
                i++;
            }

            return imageUrls;
        },
        optionComfortList() {
            return this.roomOption[0]?.product?.attributes?.amenity_labels;
        },
        isAvailable() {
            if (process.client && !this.mainCard) {
                return this.roomOption[0]?.available;
            }

            return true;
        },
        unavailableOption() {
            if (!this.isAvailable && !this.mainCard && !this.isAvailableRooms && !this.hasProduct) {
                return true;
            }

            return false;
        },
        unavailableOptionClass() {
            if (!this.isAvailable && !this.mainCard) {
                return 'greyBg yellow-border grey-color';
            }

            return '';
        },
    },
    methods: {
        ...mapMutations({
            addProduct: 'cart/addProduct',
        }),
        productHas(property) {
            if (!this.product?.attributes || typeof this.product.attributes !== 'object') return false;

            return property in this.product.attributes && this.product.attributes[property];
        },
        cardShow() {
            this.cardPopUp = !this.cardPopUp;
        },
        addProductToCart(itemValue) {
            const item = itemValue.product;
            const additionalData = {
                metadata: {
                    comment: '',
                    amount: item.amount,
                },
                pagePath: item.pagePath ?? item.attributes.product_pagePath,
                classification: item?.classification,
                icon: itemValue.icon,
            };
            this.addProduct({
                type: item.type,
                productCode: item.code,
                from: this.fromDate,
                to: this.toDate,
                image: this.optionMainImage.url,
                title: item.attributes.marketing_name,
                price: itemValue.discountAmount ? itemValue.price : itemValue.price + itemValue.discountAmount,
                location: item.location,
                persons: this.persons,
                discountAmount: itemValue.discountAmount,
                priceWithoutTax: 0,
                ...additionalData,
            });

            this.$store.dispatch('cart/getTotals');

            this.$gtm.push({
                event: 'add_to_cart',
                ecommerce: {
                    items: [this.$util.generateGtmProduct(itemValue, 'addToCart', additionalData.pagePath, this.mainTitle)],
                },
            });
        },
    },
};
